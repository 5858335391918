/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import HeroSlide from './heroSlide';
import api from '../api';
import settings from '../settings';

const url = require('url');

export default function HeroCarousel({ setSite }) {
    const [slides, setSlides] = useState();
    const [slideCount, setSlideCount] = useState(0);
    const [slideInterval, setSlideInterval] = useState(5000);
    const [isLoading, setIsLoading] = useState(true);
    const clientDomain = `${window.location.protocol}//${window.location.host}`;

    useEffect(() => {
        api.get('/herobar')
            .then(data => {
                if (isLoading) {
                    setIsLoading(false);
                    setSlides(data.slides);
                    setSlideCount(data.slideCount);
                    setSlideInterval(data.slideIntervalMs);
                }
            })
            .catch((e) => console.log(e));
    }, [isLoading]);

    return (
      <div className="hero-container">
        <CarouselProvider
          className="hero-content-box"
          infinite
          isIntrinsicHeight
          totalSlides={slideCount}
          interval={slideInterval}
          isPlaying
        >
          <Slider>
            {
              slides && slides.map((slide, idx) => (
                <Slide index={idx} key={slide.title}>
                  <HeroSlide
                    setSite={slide.isExternalLink && setSite}
                    title={slide.title}
                    img={slide.image.raw.replace(clientDomain, '')}
                    imgAlt={slide.image.alt}
                    buttonLink={slide.buttonLink.replace(clientDomain, '')}
                    buttonText={slide.buttonText}
                  >
                    <p>{slide.abstract}</p>
                  </HeroSlide>
                </Slide>
              ))
            }
          </Slider>
          <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
          <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
          <DotGroup className="hero-dots" />
        </CarouselProvider>
      </div>
    );
}
