/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import HookupArticle from '../components/hookUpArticle';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import HookUpOopsPage from '../components/hookUpOopsPage';
import PageEditor from '../components/article/pageEditor';
import ArticleRenderer from '../components/articleRenderer';

import Index from '../pages/index';
import About from '../pages/about';
import PolicyandLegislation from '../pages/policy-and-legislation';
import PFC from '../pages/PFC';
import eNewsSubscriber from '../pages/enews-subscriber';

import Login from '../pages/login';
import Logout from '../pages/logout';
import NewPost from '../pages/newPost';
import ManageArticles from '../pages/manageArticles';
import resourceRenderer from '../pages/utils/resourceListRenderer';
import featuredRenderer from '../pages/utils/featuredAssetsRenderer';
import Oops404Page from '../pages/oops-404';

import MoneyRoutes from './money-routes';
import ProtectionRoutes from './protections-routes';
import SplRoutes from './spl-routes';
import TrainingRoutes from './training-routes';
import ToolsResourcesRoutes from './tools-resources-routes';
import SavingRoutes from './saving-routes';
import BenefitsRoutes from './benefits-routes';
import PlanningRoutes from './planning-routes';
import MilitaryJourneyTPRoutes from './military-journey-tp-routes';
import AirForceResource2Routes from './air-force-resource-routes/air-force-resource-2-routes';
import UscgResourceRoutes from './uscg-resource-routes/uscg-resource-routes';
import NavyResourceRoutes from './navy-resource-routes/navy-resource-routes';
import TrendingTopicsRoutes from './trending-topics-routes';
import DollarsAndSenseBlogRoutes from './dollars-sense-blog-routes';
import MirlRoutes from './mirl-routes';
import MapRoutes from './map-routes';
import FWBARoutes from './fwba-routes';
import MapSpreadsheetRoutes from './map-spreadsheet-routes';
import EpublicationsRoutes from './epublications-routes';
import HostedRoutes from './hosted-routes';
import BetaRoutes from './beta/beta-routes';
import HomeVideoRoutes from './home-video-routes';
import PFCProgramGuideRedirectRoutes from './pfc-program-guide-redirect-routes';
import StartHereRoutes from './start-here-routes';

// temp routes
import FrnResources2022 from '../pages/frn-resources-2022';


export default function AppRoutes (props) {
    const root = props.match.path;

    return (
      <Switch>
        <Route exact path={`${root}/`} component={Index} />
        <Route exact path={`${root}/QRCode`} component={Index} />

        <Route exact path={`${root}/About`} component={About} />

        <Route path={`${root}/StartHere`} component={StartHereRoutes} />

        <Route path={`${root}/Money`} component={MoneyRoutes} />

        <Route path={`${root}/Benefits`} component={BenefitsRoutes} />

        <Route
          exact
          path={`${root}/FSA`}
          render={(routerProps) => (
            <Redirect to={`${root}/Benefits#Flexible-Spending-Accounts`} />
          )}
        />

        <Route path={`${root}/Saving`} component={SavingRoutes} />

        <Route path={`${root}/Planning`} component={PlanningRoutes} />

        <Route path={`${root}/Trending`} render={(rps) => <TrendingTopicsRoutes {...rps} />} />

        <Route path={`${root}/Consumer-Protections`} component={ProtectionRoutes} />

        <Route path={`${root}/Blog`} component={DollarsAndSenseBlogRoutes} />

        <Route path={`${root}/MIRL`} component={MirlRoutes} />

        <Route path={`${root}/Media`} render={() => <Redirect to="/ToolsAndAddRes/ShareableResources" />} />

        <Route exact path={`${root}/Policy`} component={PolicyandLegislation} />

        <Route path={`${root}/FWBA`} component={FWBARoutes} />

        <Route exact path={`${root}/PFC`} component={PFC} />

        <Route path={`${root}/PFCMap`} component={MapRoutes} />
        <Route exact path={`${root}/PFCMapSpreadSheet`} component={MapSpreadsheetRoutes} />

        <Route path={`${root}/SPL`} component={SplRoutes} />

        <Route path={`${root}/Training`} component={TrainingRoutes} />

        <Route path={`${root}/MilitaryJourneyTouchpoints`} component={MilitaryJourneyTPRoutes} />

        <Route path={`${root}/NavyResource`} component={NavyResourceRoutes} />

        <Route path={`${root}/AirForceResource`} component={AirForceResource2Routes} />

        <Route path={`${root}/CoastGuardResource`} component={UscgResourceRoutes} />

        <Route path={`${root}/ePublications`} component={EpublicationsRoutes} />

        <Route path={`${root}/ToolsAndAddRes`}>
          <ToolsResourcesRoutes root={`${root}/ToolsAndAddRes`} />
        </Route>

        <Route exact path={`${root}/eNews-subscriber`} component={eNewsSubscriber} />
        <Route exact path={`${root}/eNews-subscriber/QRcode`} component={eNewsSubscriber} />

        <Route path={`${root}/DCFSA`} render={() => <Redirect to={`${root}/Benefits/DCFSA`} />} />

        <Route exact path={`${root}/manage/articles`} component={ManageArticles} />

        <Route
          exact
          path={`${root}/articles/:_id`}
          render={routeProps => <ArticleRenderer _id={routeProps.match.params._id} />}
        />

        <Route exact path={`${root}/utils/resourceRenderer`} comp component={featuredRenderer} />

        <Route exact path={`${root}/login`} component={Login} />

        <Route exact path={`${root}/logout`} component={Logout} />

        <Route
          exact
          path={`${root}/pages/:_id/edit`}
          render={routeProps => <PageEditor _id={routeProps.match.params._id} />}
        />

        <Route exact path={`${root}/newpost`} component={NewPost} />

        <Route
          exact
          path={`${root}/articles/:_id/edit`}
          render={routeProps => <NewPost _id={routeProps.match.params._id} />}
        />

        <Route path={`${root}/Hosted`} component={HostedRoutes} />

        <Route path={`${root}/beta`} component={BetaRoutes} />

        <Route path={`${root}/video`} component={HomeVideoRoutes} />

        {/* Temp routes */}
        <Route exact path={`${root}/2022FRNResources`} component={FrnResources2022} />

        <PFCProgramGuideRedirectRoutes root={root} />

        {[
            HookupArticle(root, null, null, 'SiteMap.md', 'site-map', 'Site Map'),
        ]}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
