/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/page';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function Money (props) {
    const root = props.match.path;

    return (
      <Page
        articleClass="launch-page"
        {...props}
      >
        <ReactMarkdown
          source={`
## Managing Your Money
You’ve earned your paycheck and the Office of Financial Readiness can show you ways to make the most of it. Learn to manage your money and create a spending plan that lets you pay your living expenses today while saving for tomorrow. You can even find tips and ideas for setting aside money in an emergency fund or vacation account.

[Credit-Fundamentals]()
### Credit Fundamentals
It’s time for a credit checkup! How you manage your money can affect all areas of your life. Learn how to own your finances and make credit work for you. You also have accredited personal financial managers and counselors at your fingertips. Set up a no-cost appointment at your nearest Military and Family Support Center to review your finances. 
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-MCM-BuyNowPayLater-I.jpg"
            alt="Exploring the Buy Now/Pay Later option infographic."
            title="Exploring the Buy Now/Pay Later Option"
            href="/assets/downloads/FINRED-MCM-BuyNowPayLater-I.pdf"
            type="Article"
            link="Read Now"
          >
            <p>
            Buy now/pay later is a financing option that’s been growing in popularity. This infographic explains what to know before considering it.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-OperationCredit-A.jpg"
            alt="Man using laptop with Consumer Financial Protection Bureau credit reports and scores website"
            title="Operation: Monitor Your Credit"
            href={`${root}/OperationCredit`}
            type="Article"
            link="Read Now"
          >
            <p>
            Guard your financial reputation. Learn about the importance of credit monitoring, how to do it and resources that can help, all in this article.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-WarningSigns-A.jpg"
            alt="Woman entering password on cell phone"
            title="Spot the Warning Signs of Identity Theft"
            href={`${root}/WarningSigns`}
            type="Article"
            link="Read Now"
          >
            <p>
            Service members are often the target of scammers. Read this article and learn how to better pinpoint signs of identity theft and protect your digital information.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Financial Fundamentals: Establishing and Improving Your Credit History](${root}/CreditHistory)
Building a good credit history is important. Find out how your credit score and history impact your long-term financial health.   
* [Financial Fundamentals: Managing and Monitoring Credit](${root}/ManagingCredit)
Having good credit gives you choices. Learn how to increase your credit score by managing and monitoring your credit. 
* [Freeze Your Credit Information: Keep Unwanted Visitors out in the Cold](/assets/downloads/FINRED-CreditFreeze-I.pdf)
Learn how to take advantage of useful tools, like credit freezing, that can help prevent fraud and safeguard your credit.
* [Guard What’s Yours: Monitor Your Credit](/assets/downloads/FINRED-MonitorCredit-I.pdf)
Take advantage of freebies. As a service member you can monitor your credit score at no cost to you!
* [Understand the Ins and Outs of Credit](${root}/InOutCredit)
Stay on top of your credit — help prevent fraud or identity theft by understanding your credit score and managing your credit report.
* [What To Do If Identity Theft Happens to You](${root}/IDTheft)
Identity theft can happen to anyone, but there are steps you can take to reduce your risk. Learn about how to protect yourself and your family in this article.
* [Your Credit Score Matters: Find Out Why](/assets/downloads/FINRED-CreditScoreMatters-P.pdf)
You probably know there are benefits to having a high credit score, but do you know how to get there? Learn how to begin improving your score today.

[Digital-Assets]()
### Digital Assets
Thinking about dealing in digital assets? Learn what they are, determine if purchasing them is right for you and know the tax implications of some common transactions.
          `}

          renderers={{
              link: MarkLink,
          }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CuriousAboutCrypto-A.jpg"
            alt="Cryptocurrency blockchains"
            title="Curious About Cryptocurrency?"
            href={`${root}/CuriousAboutCrypto`}
            link="Read Now"
          >
            <p>
            Curious about cryptocurrency? Here’s a primer to help you get started.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DigitalAssetsTaxes-FS.jpg"
            alt="Tax Implications of Digital Assets Fact Sheet"
            title="Tax Implications of Digital Assets"
            href="/assets/downloads/FINRED-DigitalAssetsTaxes-FS.pdf"
            link="Learn More"
          >
            <p>
            As with almost all financial transactions, there are tax implications — and digital assets are no different. Know what your tax obligations are with this helpful fact sheet.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-MOSPodcast.jpg"
            alt="Military OneSource Podcast"
            title="Military OneSource Cryptocurrency Podcast"
            href="https://www.militaryonesource.mil/training-resources/podcasts/military-onesource/cryptocurrency"
            link="Visit Now"
          >
            <p>
            Curious about cryptocurrency? In this podcast, Dan Rutherford of the U.S. Commodity Futures Trading Commission describes cryptocurrency, how it works and the pitfalls to watch out for.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
[Major-Purchases]()
### Major Purchases
You picked out what you want, saved up a deposit and now you’re ready to make a major purchase … right? Before signing on the dotted line, use these resources to help you feel more confident in your decision. You can also make an appointment with a personal financial manager or counselor at your nearest installation for additional support.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-VS.jpg"
            alt="Home Buying Basics logo"
            title="Home Buying Basics"
            href={`${root}/HomeBuyingBasics`}
            type="Infographic"
            link="Watch Now"
          >
            <p>
            Should you rent or buy your next home? Follow several military families and a single service member during their home buying journeys in this seven-part video series.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-MajorPurchases-Sign-A.jpg"
            title="Make Major Purchases With Care and Confidence"
            alt="Service member signing documents"
            href={`${root}/MajPurchase-Sign`}
            link="Read Now"
          >
            <p>
            Don’t rush a major purchase. Take time to plan, compare options, and research products and suppliers in order to make the best choice for you.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CarBuyingBasicsSeries-VS.jpg"
            alt="Watch Now"
            title="Car Buying Basics"
            href={`${root}/CarBuyingBasics`}
            type="Video Series"
            link="Watch Now"
          >
            <p>
            Get the tips and insights you need to navigate the car buying process — all in this accessible and entertaining six-part video series.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Car Buying 101: 5 Steps to Vehicle Financing](/assets/downloads/FINRED-5StepsFinancing-I.pdf)
Ready to buy a car? Make sure you check off these five steps to vehicle financing before you sign on the dotted line.
* [Car Buying 101: How To Negotiate Your Total Car Payment](/assets/downloads/FINRED-NegotiatePayment-I.pdf)
Instead of negotiating your ideal monthly car payment, use these tips to focus negotiations on the full picture of your investment.
* [Car Buying 101: How To Win the Negotiation Game](/assets/downloads/FINRED-CarBuying-I-NegotiationGame.pdf)
Don’t get caught off guard by sales tactics when buying a car. Here’s how you can gain the upper hand and win the negotiation game.
* [Car Buying 101: New vs. Used](/assets/downloads/FINRED-NewVsUsed-I.pdf)
Buying a car is a big decision — and choosing between new or pre-owned is the first step. Here’s a breakdown of what you should consider.
* [Car Buying 101: What You Need To Know About Dealer Add-Ons](/assets/downloads/FINRED-DealerAddOns-I.pdf)
Don’t get caught off guard by unexpected dealer add-ons. Here’s what you need to know about your options before you commit to a car purchase.
* [Car Buying 101: When Your Trade-In Has Negative Equity](/assets/downloads/FINRED-NegativeEquity-I.pdf)
Should you buy a new car when your current car has negative equity? The answer is probably not — and here’s why.
* [Know the True Cost of Your Loan](/assets/downloads/FINRED-TrueLoanCost-I.pdf)
Before you take out a loan for a major purchase or other expense, make sure you understand how the terms will affect your total cost. See the breakdown in this infographic.

[Pay-and-Savings]()
### Pay and Savings
Make your money work for you! Stretch your paycheck to cover your bills and expenses and still have money left for your savings and retirement accounts. Use these resources and ideas to get started and make an appointment with an accredited personal financial manager or counselor at your installation Military and Family Support Center for no-cost, personal support.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-EOY-DDP.jpg"
            alt="Clock and calendar icon with money"
            title="Use This Comprehensive Checklist to Prepare for a Prosperous New Year"
            href={`${root}/NewYearChecklist`}
            link="Get Started"
          >
            <p>
            December and January are the perfect time to check items off your financial to-do list and set financial goals for the new year.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TypesofPay-I.jpg"
            alt="Female service member looking at paperwork"
            title="Your Leave and Earnings Statement: Understanding Your Pay"
            href="/assets/downloads/FINRED-TypesOfPay-I.pdf"
            link="Download"
          >
            <p>
            The first step to taking control of your finances is understanding your income. Start here with a breakdown of the different types of military pay.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-EmergencyFund-V.jpg"
            alt="Service member and family visit a personal financial manager."
            title="Emergency Funds: How Much Is Enough?"
            href={`${root}/video/Emergency-Funds-How-Much-Is-Enough`}
            link="Watch Now"
          >
            <p>
            We’ve all been there: Our car breaks down. Our pet gets sick. Our teeth need work. That’s why we all need an emergency fund. This video explains how to decide the amount.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Achieve Goals and Peace of Mind Through Financial Planning](${root}/AchieveGoals)
Want to achieve your goals and gain peace of mind? Use the information and resources you'll find here to make it happen with wise financial planning.
* [A Tale of Two Choices: Beware of Lifestyle Inflation](/assets/downloads/FINRED-TaleofTwoChoices-I.pdf)
It can be easy to spend more as you earn more, which is known as lifestyle inflation. Learn how one service member confronted it in this infographic.
* [Budgeting in Uncertain Times](${root}/BudgetingUncertainTimes)
When unexpected circumstances lead to financial instability, use these tips to assess your spending plan and manage your new financial situation.
* [Financial Well-Being: How To Assess, Improve and Maintain Yours](${root}/FinancialWellBeing)
Do you know what it means to have financial well-being? Learn more about assessing and improving your financial well-being in this article.
* [How To Avoid — or Break — the Debt Trap Cycle](${root}/DebtTraps)
Getting into a debt trap can feel endless. In this article, you’ll learn steps you can take to break the cycle and improve the health of your finances.
* [It's Time to Schedule a Checkup — For Your Finances](${root}/video/Schedule-a-Checkup-For-Your-Finances)
It's time to check on your vitals — your financial vitals! Tune in to this video to find out how checkups can help you achieve and sustain your goals.
* [Know the Importance of Financial Well-Being](${root}/FinancialLiteracy)
Learning how to manage your finances is a lifelong skill. This article explores why financial well-being matters for service members and families.
* [Let's Get Financially Fit](/assets/downloads/FINRED-FinanciallyFit-I.pdf)
Get pumped about your personal finances! Check out this infographic for five introductory steps (and a few exercise puns) to help you build financial fitness.
* [Planning Ahead for Your Next Raise](/assets/downloads/FINRED-PlanningAhead-I.pdf)
Making more money can lead to more spending. This infographic can help you learn strategies to help manage lifestyle inflation with your next pay raise.
* [Spending Plan Worksheet](/assets/downloads/FINRED-Spendingplan-TK.pdf)
Count on a bright financial future! Use our Spending Plan Worksheet to take charge of your spending by tracking, budgeting and planning ahead.
* [Take Charge of Your Energy Costs](/assets/downloads/FINRED-EnergySavings-I.pdf)
Concerned about rising energy costs? Take charge! Lower the temperature on your energy costs by cutting consumption. Check out the tips, tricks and resources in this infographic.
* [The Impact of Inflation on Financial Decisions](${root}/Inflation)
Do you know how inflation affects your investments and assets? This article explores the impact of inflation on the long-term value of your money.
* [What To Know About Lending Apps](${root}/LendingApps)
Modern lending apps may seem like an attractive quick fix to financial problems, but they aren’t without risks. This article explores important considerations.
* [What’s Your Spending Personality?](${root}/SpendingPersonalities)
Achieving financial health means taking an honest look at spending. Discover five spending personalities and actions to improve money management habits in this article.
* [Why Understanding Different Types of Military Pay Is Crucial for Your Finances](${root}/TypesOfPay)
Your military paycheck includes different types of pay. Knowing what you rate and understanding each type helps ensure you’re paid what you earned.

[Tax-Information]()
### Tax Information
Taxes play a big role in personal finances. Withholding choices impact take-home pay, and owing money or receiving a refund at tax time can have a major impact on household spending plans. Use these resources to maximize your benefits and protect against an unexpected tax bill or penalty. No-cost, trusted support is also available at your nearest Military and Family Support Center.
        
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-MIRL-8TaxTips-I.jpg"
            alt="8 Tax Tips Infographic"
            title="8 Tips To Maximize Your Savings During Tax Season"
            href="/assets/downloads/FINRED-MIRL-8TaxTips-I.pdf"
            link="Download"
          >
            <p>
            This tax season don’t leave money on the table. This infographic outlines credits and deductions you may be entitled to and offers no-cost tax filing resources.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TaxImplicationsRetirement-A.jpg"
            alt="Financial counselor at desk with service member"
            title="Financial Planning for Transition: The Tax Implications of Retirement"
            href={`${root}/RetirementTaxes`}
            link="Read Now"
          >
            <p>
            As you plan for your retirement, don’t let a change in your taxes leave you with a surprise bill. This article shows you how to plan ahead to avoid the unexpected.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TaxMistakes-A.jpg"
            alt="Filling out tax forms"
            title="Avoid These Common Tax Mistakes"
            href={`${root}/TaxMistakes`}
            link="Read Now"
          >
            <p>
            This article shows you how to avoid common tax mistakes, correct any oversights and errors and access resources to use now and in the future.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Don’t Wait: Plan Now to Reap Tax Benefits Later](${root}/TaxBenefits)
Reviewing your tax strategies now, before the current year ends, could save you money and help you earn additional tax credits. This article offers some tips.
* [Operation Tax Planning: Civilian Career](${root}/OperationTaxPlanning)
As you begin searching for a post-military career, consider how civil tax implications may impact your take-home pay. This article can help you crunch the numbers.
* [Talking to Service Members About Filing Taxes](/assets/downloads/FINRED-TaxFiling-LC.pdf)
Filing taxes while in the military can get complicated. Use this leader card to discuss the benefits and resources available to your service members to help them navigate the process.
* [Tax Advice for First-Time Filers](${root}/FirstTimeFilers)
Filing taxes for the first time is a major milestone for any young service member. Read the tips in this article to help you get started.
* [Tax Planning and the Gig Economy](/Trending/Blog/1099Tax)
Looking to make extra income? The gig economy could be a possibility, but there are tax implications for service members and their spouses. Find out more.
* [Why Is Money Missing From My Paycheck? Introducing Your Teen to Taxes](/Trending/Blog/TeenTaxes)
Your teenager received his or her first paycheck. Here are a few tips for you, their parent, to introduce a new worker to paying and understanding taxes.
        `}
        />

        <ReactMarkdown
          source={`
[Additional-Information]()
### Additional Information
Don’t just assume that last year’s plan is still the best approach. Use these resources to help you budget and discover new and better ways to save. Don’t hesitate to check in with a personal financial manager or counselor to make sure you’re still making the best choices for your goals.
        `}
        />
        <div className="usa-grid featured-assets">

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBA-TK.jpg"
            alt="A service member filling out paper form on clipboard in front of laptop."
            title="Financial Well-Being Assessment"
            href="/FWBA"
            link="Learn More"
          >
            <p>
            When is the last time you checked on your financial health? Use the assessment and resources on this page to evaluate your current situation and take control of your financial well-being.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-KidsMoney-V.jpg"
            title="Kids Answer Financial Questions"
            href={`${root}/video/Kids-Answer-Financial-Questions`}
            link="Watch Now"
          >
            <p>
            As a parent you know the importance of financial literacy. Find out why it’s important to teach your kids about money and how to manage it.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ManagingMoney-R.jpg"
            title="Managing Your Money Additional Resources"
            href={'/ToolsAndAddRes/AdditionalRes/Money'}
            type="Collaborator Resources"
            link="Learn More"
          >
            <p>
            Get back to the basics of money management with these resources that will help you secure your spending plan and feel confident in your finances.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
