/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../components/hookUpSiteVideo';
import HookUpOopsPage from '../components/hookUpOopsPage';


export default function HomeVideoRoutes(props) {
    const root = props.match.path;
    // const crumbs = props.crumbs ? `${props.crumbs},Vesting In TSP|${root}` : `Vesting In TSP|${root}`;
    const crumbs = '';

    return (
      <Switch>
        {[
            HookUpSiteVideo(
                root, 'ACompensationConversation', 'A Compensation Conversation', null,
                '/assets/video/mos/15959_MOS_TCOP_Compensation_Vid.mp4',
                null, '/assets/img/FINRED-CompVideo-Oct2024-HomepageBanner.jpg',
            ),
            HookUpOopsPage(),
        ]}
      </Switch>
    );
}
