/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route } from 'react-router-dom';

import RenderToPDF from '../components/RedirectToPDF';

export default function PFCProgramGuideRedirectRoutes (props) {
    return (
        [
          <Route
            exact
            path={`${props.root}/April2024PFC`}
            render={() => <RenderToPDF to="/assets/downloads/FINRED-PFC-G.pdf" />}
          />,
          <Route
            exact
            path="/assets/downloads/FINRED-ThePFCProgramandMe-April2024.pdf"
            render={() => <RenderToPDF to="/assets/downloads/FINRED-PFC-G.pdf" />}
          />,
          <Route
            exact
            path={`${props.root}/April2024PFC/video/PFCAndMe2024Webinar`}
            render={() => <RenderToPDF to="/assets/downloads/FINRED-PFC-G.pdf" />}
          />,
          <Route
            exact
            path="/assets/downloads/FINRED-PFCAndMe2024Webinar-7May2024-SC-ForGov.docx"
            render={() => <RenderToPDF to="/assets/downloads/FINRED-PFC-G.pdf" />}
          />,
          <Route
            exact
            path="/assets/downloads/FINRED-ThePFCProgramMe-QA-7May2024-SC-ForGov.pdf"
            render={() => <RenderToPDF to="/assets/downloads/FINRED-PFC-G.pdf" />}
          />,
          <Route
            exact
            path="/assets/downloads/FINRED-ServicePOCList.pdf"
            render={() => <RenderToPDF to="/assets/downloads/FINRED-PFC-G.pdf" />}
          />,
        ]
    );
}
