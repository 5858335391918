/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import T4Header from '../template-components/t4Header';
import T4ContentBlock from '../template-components/t4ContentBlock';
import DdFooter from '../template-components/dd-footer';

export default function Template4 ({ elements }) {
    return (
      <div className="dd-t3">
        <T4Header header={elements.header} />
        {
          elements.contentBlocks && elements.contentBlocks.map(cb => (
            <T4ContentBlock contentBlock={cb} />
          ))
        }
        { elements.footer && <DdFooter footer={elements.footer} /> }
      </div>
    );
}
