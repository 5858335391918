/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import DCFSA from '../pages/dcfsa/dcfsa';
import FAQ from '../pages/dcfsa/faq';
import DCFSAVideosRoutes from './dcfsa-videos-routes';
import DCFSAStrengthingOurSupport from '../pages/dcfsa/dcfsa-strengthing-our-support';
import DCFSAWebinars from '../pages/dcfsa/dcfsa-webinars';
import BenefitsTOC from '../components/tocs/benefits';
import Oops404Page from '../pages/oops-404';
import RenderToPDF from '../components/RedirectToPDF';

// import HookUpArticle from '../components/hookUpArticle';

export default function DcfsaRoutes(props) {
    const root = props.match.path;
    const crumbs = `${props.crumbs},Understanding the Dependent Care Flexible Spending Account|${root}`;
    const toc = () => (props.toc || BenefitsTOC);

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <DCFSA {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/FAQ`}
          render={(routerProps) => <FAQ {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          path={`${root}/Videos`}
          render={(routerProps) => <DCFSAVideosRoutes {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/EligibilityGuide`}
          render={() => <RenderToPDF to="/assets/downloads/FINRED-DCFSA-Eligibility-G-Printable.pdf" />}
        />

        <Route
          exact
          path={`${root}/Webinars`}
          render={(routerProps) => <DCFSAWebinars {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/StrengtheningOurSupport`}
          render={(routerProps) => <DCFSAStrengthingOurSupport {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        {/* {[
            HookUpArticle(root, crumbs, BenefitsTOC, 'ServiceResources.md', 'Benefits', 'FAQ and Service Resources'),
        ]} */}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

