/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import HeaderTitle from './headerTitle';
import HeaderAbstract from './headerAbstract';
import ContentBlockHeader from './contentBlockHeader';


export default function T4Header ({ header }) {
    return (
      <>
        <div className="dd-t4-header">
          <HeaderTitle text={header.title.text} />
          <ContentBlockHeader header={header.subHeader} />
          <div className="dd-t4-abstract-image-container">
            <HeaderAbstract text={header.abstract.text} />
            <div className="dd-image">
              <img src={header.image.src} alt={header.image.alt} title={header.image.alt} />
            </div>
          </div>
        </div>
      </>
    );
}
